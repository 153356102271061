import {Component, OnInit, ViewChild} from '@angular/core';
import {SvdsVehicleDetailV4} from '../dtos/SvdsVehicleDetailV4';
import {IntegrationService} from '../services/integration/integration.service';
import {CosyImageAngleUrl} from '../dtos/CosyImageAngleUrl';
import {DataService} from '../services/data.service';
import {ClearanceDto} from '../dtos/ClearanceDto';
import {TranslateService} from '@ngx-translate/core';
import {ClearanceStatus} from '../dtos/ClearanceStatus';
import {ErrorParentDto} from '../dtos/ErrorDto';
import {catchError} from 'rxjs/operators';
import {of} from 'rxjs';
import {AssignmentDto} from '../dtos/AssignmentDto';
import {PackageDetailsDto} from '../dtos/PackageDetailsDto';
import {ViewSource} from '../dtos/ViewSource';
import {ShowDetailsWrapper} from '../dtos/ShowDetailsWrapper';
import {TelematicKeyDto} from '../dtos/TelematicKeyDto';
import {AuthService} from "../services/auth.service";
import {Location} from "@angular/common";
import {addSeconds} from "@bmw-ds/components";
import {RedirectStatus} from "../dtos/RedirectStatus";
import {AssignmentStatus} from "../dtos/AssignmentStatus";
import {Brand} from "../dtos/Brand";
import "@neo-ds/components/notification";
import "@neo-ds/components/button";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'BMW CarData Consent Portal';
  @ViewChild('imprintModal') imprintModal: any;

  imageUrl: CosyImageAngleUrl | undefined;
  vehicleData: SvdsVehicleDetailV4 | undefined;
  clearance: ClearanceDto | undefined;
  assignment: AssignmentDto | undefined;

  details: PackageDetailsDto = {
    assignment: undefined,
    clearance: undefined,
    source: undefined
  };

  params: any = {};
  public appStatus: 'LOADING' | 'FAILED' | 'LOADED' = 'LOADING';
  appError: string | null = null;

  isImprintModalOpen = false;

  showPackage = false;

  _brand: Brand = Brand.GROUP;


  constructor(
    private service: IntegrationService,
    private dataService: DataService,
    private translateService: TranslateService,
    private authService: AuthService,
    private location: Location
  ) {
  }

  get brand(): string {
    return this._brand;
  }

  set brand(val: string) {
    val = val.toUpperCase();
    if (val?.includes("MOTORRAD") && !this.isUSHub()) {
      this._brand = Brand.BMW;
      this.theme = "mo";
    } else if (val?.startsWith('BMW')) {
      this._brand = Brand.BMW;
      this.theme = "bmw";
    } else if (val?.startsWith('MINI')) {
      this._brand = Brand.MINI;
      this.theme = "mini";
    } else {
      this._brand = Brand.GROUP;
      this.theme = "group";
    }
    this.appStatus = 'LOADED';
  }

  ngOnInit(): void {
    this.saveParams();
    if (this.dataService.config.isDev) {
      console.warn("Bypassing security checks in Developer mode...")
      void this.loadData();
    } else {
      this.checkAuth();
      setInterval(() => {
        if (this.dataService.expiry < new Date()) {
          if (this.dataService.config.isE2E)
            console.log(`cookie expired, redirecting`);
          this.authService.startOnePageLogin();
        }
      }, 1000 * 60 * 2);
    }
  }

  isUSHub() {
    return this.dataService.config.isUSHub;
  }

  saveParams() {
    this.params = new URLSearchParams(window.location.search);

    if (this.params.get("clearanceid") || this.params.get("clearanceId")) {
      this.dataService.clearanceId = this.params.get("clearanceid") || this.params.get("clearanceId");
    } else if (this.dataService.config.isDev) {
      this.dataService.clearanceId = "12341234-1234-1234-1234-123412341234";
    }
    if (this.params.get("assignmentUuid") || this.params.get('assignmentid') || this.params.get('assignmentId')) {
      this.dataService.assignmentUuid = this.params.get("assignmentUuid") || this.params.get("assignmentid") || this.params.get("assignmentId");
    } else if (this.dataService.config.isDev) {
      this.dataService.assignmentUuid = "ABC1234567890";
    }

    if (this.params.get('country')) this.dataService.country = this.params.get('country');

    if (this.params.get('brand')) this.dataService.brand = this.params.get('brand');

    if (this.params.get('redirecturl') || this.params.get('redirectUrl'))
      this.dataService.redirectUrl = this.params.get('redirecturl') || this.params.get('redirectUrl');
  }

  get language() {
    return this.translateService.currentLang;
  }

  async getAuthCode(): Promise<string | null> {
    return new Promise(async (resolve) => {
      const params = new URLSearchParams(window.location.search);
      const access_token = params.get('access_token');
      if (access_token) {
        this.dataService.expiry = addSeconds(new Date(), Number(params.get('expires_in')) || 1);
        this.dataService.accessToken = access_token;
      }


      const code = params.get('code');
      console.log(`getAuthCode ${code}`);
      if (code) {
        console.log(`Code = ${code}`);
        this.location.replaceState('/');
      }
      resolve(code)
    });
  }

  async checkAuth() {
    setTimeout(() => {
      if (this.appIsLoading) {
        console.log(`checkAuth timeout`);
        this.appStatus = 'FAILED';
      }
    }, 8000);
    const authCode = await this.getAuthCode();
    console.log(`authCode`, authCode)
    if (authCode) {
      //auth back
      this.service.getAccessTokenForCode(authCode)
        .then(res => {
          console.log(res);
          this.dataService.accessToken = res.access_token;
          this.loadData();
        })
    } else if (!this.accessToken) {
      this.authService.startOnePageLogin()
    } else if (this.accessToken) {
      this.loadData();
    }
    console.log(`AccessToken = ${this.accessToken}`)
  }

  loadDataRetry = 0;
  loadDataRetryMax = 5;

  _retryLoadData() {
    if (this.loadDataRetry < this.loadDataRetryMax) {
      setTimeout(() => {
        this.loadDataRetry++;
        this.loadData();
      }, 200);
    } else {
      console.log(`retryLoadData failed`);
      this.appStatus = 'FAILED';
    }
  }

  public async loadData() {
    this.service
      .authToken()
      .pipe(
        catchError((err: any) => {
          if (err.status == 401) {
            this.appError = 'Unauthorized';
            this.userAction(RedirectStatus.G_UNAUTHORIZED);
            return of({errorResponse: 'Unauthorized'});
          }
          return of(err);
        })
      )
      .subscribe((value) => {
        const gcid = value[0]?.gcid || '';
        if (!gcid || gcid?.length === 0) {
          this.userAction(RedirectStatus.UNAUTHORIZED);
        } else {
          this.dataService.gcId = gcid;
          if (this.clearanceId) this.getClearance(gcid);
          if (this.assignmentUuid) this.getAssignment(gcid);
          this.appStatus = 'LOADED';
        }
      });
  }

  loadMetaData(vin: string, gcid: string) {
    return Promise.all([this.getVehicleData(vin, gcid), this.loadCosyImage(vin)]);
  }

  getClearance(gcid: string) {
    console.log(`fetching clearance`);
    return new Promise((resolve) => {
      this.service
        .getClearance(gcid)
        .pipe(
          catchError((err: any) => {
            if (err.error.statusCode == 401) {
              this.appError = 'Unauthorized';
              this.userAction(RedirectStatus.UNAUTHORIZED);
              return of({
                errorResponse: {
                  error: 'Unauthorized',
                  category: 'UNAUTHORIZED'
                }
              });
            }
            return of(err);
          })
        )
        .subscribe(async (clearance: ClearanceDto | ErrorParentDto) => {
          console.log(`clearance`, clearance);
          if ('errorResponse' in clearance) {
            console.log(clearance);
            if ('category' in clearance?.errorResponse)
              this.userAction(clearance?.errorResponse?.category);
            if (this.loadDataRetry >= this.loadDataRetryMax) {
              this.appError = clearance?.errorResponse?.message ?? 'Unknown clearance error';
              this.appStatus = 'FAILED';
            }
            return;
          }

          if ('vin' in clearance) {
            // if (this.isUSHub() && gcid != clearance.gcid) {
            //   this.userAction(RedirectStatus.SECONDARY_USER_UNAUTHORIZED);
            //   return;
            // }
            this.clearance = clearance;
            this.details.clearance = this.clearance;
            this.dataService.addTranslatedTelematicKeys(
              this.translateService.defaultLang,
              this.clearance.telematicKeys
            );
            console.log(this.appStatus);

            const status = this.details.clearance.status || '';
            const vin = this.details.clearance?.vin ?? this.details.assignment?.vin;

            if ([ClearanceStatus.APPROVED].includes(status)) {
              this.userAction();
              return resolve(clearance);
            }

            this.loadDataRetry = 0;
            await this.loadMetaData(vin, gcid);
            resolve(clearance);
          } else {
            console.log(`clearance not received, retrying`);
            this._retryLoadData();
            this.appStatus = 'LOADED';
            resolve(clearance);
          }
        });
    });
  }

  getAssignment(gcid: string) {
    return new Promise(async (resolve) => {
      if (gcid) {
        this.service.getAssignment(gcid).subscribe(async (val) => {
          console.log(val);
          this.assignment = val;
          this.details.assignment = this.assignment;


          const status = this.details.assignment.status || '';
          const vin = this.details.clearance?.vin ?? this.details.assignment?.vin ?? '';

          // CARDATA-13244
          if (![AssignmentStatus.REQUESTED].includes(status)) {
            this.userAction();
            return resolve(val);
          }

          await this.loadMetaData(vin, gcid);
          this.appStatus = 'LOADED';
          resolve(val);
        });
      }
      resolve(null);
    });
  }

  getVehicleData(vin: string, gcid: string): Promise<SvdsVehicleDetailV4 | null> {
    return new Promise((resolve) => {
      if (vin)
        this.service.getVehicleData(vin, gcid).subscribe((val) => {
          this.vehicleData = val;
          this.brand = val.brand;
          resolve(val);
        });
      resolve(null);
    });
  }

  loadCosyImage(vin: string): Promise<CosyImageAngleUrl | null> {
    return new Promise((resolve) => {
      if (vin) {
        this.service.getCosyImage(vin, 40, 2000).subscribe((val) => {
          if (val) {
            this.imageUrl = val;
          } else {
            console.log(`received no image, retrying`);
            const vin = this.details.clearance?.vin ?? this.details.assignment?.vin ?? '';
            setTimeout(() => this.loadCosyImage(vin), 200);
          }
          resolve(val);
        });
      }
      resolve(null);
    });
  }

  /**
   * Overwrite the status by passing a value to status, if empty, it will return the clearance or assignment status
   * @param status
   */
  userAction(status?: RedirectStatus | ClearanceStatus | AssignmentStatus | string) {
    this.service.redirectBackTo(
      this.clearanceId || this.assignmentUuid,
      status ||
      (this.details.clearance?.status ?? this.details.assignment?.status),
      this.clearanceId ? ViewSource.CLEARANCE : ViewSource.ASSIGNMENT
    );
  }

  getError() {
    let details = "";
    if (!this.clearanceId && !this.assignmentUuid) {
      details += '<br>- basic data missing, either pass a clearanceId or an assignmentId';
    }
    if (!this.accessToken) {
      details += '<br>- access_token missing';
    }
    return details.length > 0
      ? 'There was a failure :(' + details
      : this.appStatusError();
  }

  appStatusError() {
    return this.appIsFailed
      ? this.appError
        ? this.appError
        : 'An error occurred, please try again later'
      : null;
  }

  get assignmentUuid() {
    return this.dataService.assignmentUuid;
  }

  set assignmentUuid(val: string) {
    this.dataService.assignmentUuid = val;
  }

  get clearanceId() {
    return this.dataService.clearanceId;
  }

  set clearanceId(val) {
    this.dataService.clearanceId = val;
  }

  get accessToken() {
    return this.dataService.accessToken;
  }

  set accessToken(val) {
    this.dataService.accessToken = val;
  }

  // calling this will open the modal
  openImprintMethod() {
    this.isImprintModalOpen = true;
  }

  // calling this will dismiss the modal
  closeImprintModal() {
    this.isImprintModalOpen = false;
  }

  showPackageView(wrapper: ShowDetailsWrapper | any) {
    if (wrapper.source) {
      this.details.source = wrapper.source;
    }
    this.showPackage = wrapper.shouldShow;
  }

  hideAll(_event: any) {
    this.showPackage = false;
  }

  selectedMenuEvent(event: any) {
    this.appStatus = 'LOADING';
    if (this.clearance) {
      const cachedKeys = this.dataService.getTelematicKeys(
        event?.toLowerCase()
      );
      if (cachedKeys) {
        this.clearance.telematicKeys = this.filterTelematicKeys(cachedKeys);
        this.appStatus = 'LOADED';
      } else {
        this.service
          .getTelematicKeysForLanguage(event?.toLowerCase())
          .subscribe((response) => {
            // @ts-ignore
            this.clearance.telematicKeys = this.filterTelematicKeys(response);
            this.dataService.addTranslatedTelematicKeys(
              event?.toLowerCase(),
              response
            );
            this.appStatus = 'LOADED';
          });
      }
    }
    this.appStatus = 'LOADED';
  }

  get appIsLoaded() {
    return this.appStatus == 'LOADED'
  }

  get appIsLoading() {
    return this.appStatus == 'LOADING'
  }

  get appIsFailed() {
    return this.appStatus == 'FAILED'
  }

  private filterTelematicKeys(telematicKeys: TelematicKeyDto[]) {
    const filteredBusinessIds: string[] | undefined =
      this.clearance?.telematicKeys.map((one) => one.businessId);
    return telematicKeys.filter(
      (one) => filteredBusinessIds?.includes(one.businessId)
    );
  }

  get showNonPrimaryUserBanner() {
    return ![this.clearance?.gcid, this.assignment?.gcid].includes(this.dataService.gcId);
  }

  get theme(): string {
    for (let i = 0; i < document.body.classList.length; ++i) {
      const c = document.body.classList[i];
      if (c.startsWith("theme-")) {
        return c.substring("theme-".length);
      }
    }

    return "group";
  }

  set theme(theme: "bmw" | "group" | "mini" | "mo") {
    document.body.classList.remove("theme-" + this.theme);
    document.body.classList.add("theme-" + theme);
  }
}
